import React from 'react'
import { Carousel } from 'react-bootstrap' 
import ButtonComponent from './ButtonComponent'
import { Link } from 'react-router-dom'

const CarouselSite = () => {
  return (
    <Carousel className='container__top'>
      
      <Carousel.Item>
        <img
          className="d-block w-100 carousel__container--images"
          src="./images/carousel/harispe-agrimensura.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='carousel__titulo'>HARISPE Agrimensura</h3>
          <p className='carousel__texto'>Desde hace 40 años prestando servicios profesionales de agrimensura y topografia en la Provincia de Bs. As. </p>
          <Link to={'quienes-somos'}><ButtonComponent  text={'Mas Info'} link={'#'} classButton={'button-rounded'} /></Link>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 carousel__container--images"
          src="./images/carousel/dron.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='carousel__titulo'>Servicios</h3>
          <p className='carousel__texto'>Prestamos gran variedad de servicios de acuerdo a las necesidades de nuestros clientes, contando con tecnología de última generación para brindar así mejores resultados.</p>
          <Link to={'servicios'}><ButtonComponent  text={'Mas Info'}  classButton={'button-rounded'}   /></Link>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 carousel__container--images "
          src="./images/carousel/equipos.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className='carousel__titulo'>Equipamiento.</h3>
          <p className='carousel__texto'>Contamos con equipamiento que nos permite ser eficientes y precisos en nuestro trabajo.</p>
          <Link to={'equipamiento'}><ButtonComponent  text={'Mas Info'} link={'#'}  classButton={'button-rounded'}  /></Link>
        </Carousel.Caption>
      </Carousel.Item>


    </Carousel>
  )
}

export default CarouselSite