import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import GalleryQuienesSomos from '../common/GalleryQuienesSomos'
import Header from '../common/Header'

const QuienesSomos = () => {
  return (

    <Container fluid className='p-0' >
      <Header urlImg='./images/headers/quienes-somos.jpg' titulo='Quienes sosmos'/>
        <Container className='section__container'>
          <Row className='section__container--row'>
            <Col xs={12} lg={4} className='section__container--col'>
              <p className='qsomos__text--bloque'>
              Somos profesionales de la Agrimensura, egresados de la Facultad de Ingeniería de la UNLP.<br /><br />
              Formamos un sólido equipo en el que se combina la experiencia de más de 40 años en la profesión, con la incorporación de las nuevas tecnologías, brindando así un servicio eficaz y de calidad, a medida de las necesidades de nuestros clientes</p>
            </Col>
            <Col xs={12} lg={4} className='qsomos__img'>
              <img src="./images/arnaldo-harispe.png" alt="Arnaldo Harispe." />
              <p className='qsomos__txt'>Arnaldo Harispe<br />Agrimensor Mat. 979 CPA</p>
            </Col>
            <Col xs={12} lg={4}  className='qsomos__img'>
              <img src="./images/juan-martin-harispe.png" alt="Juan Martin Harispe." />
              <p className='qsomos__txt'>Juan Martín Harispe<br />Ingeniero Agrimensor Mat. 2788 CPA<br />Mat. 3590 (CABA)</p>
            </Col>
          </Row>
          <Row className='pt-4'>
            <GalleryQuienesSomos />
          </Row>
        </Container>
    </Container>
    
  )
}

export default QuienesSomos