import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'
import CarouselEquipamiento from './CarouselEquipamiento';

import './Gallery.css'

const GalleryEquipamiento = () => { 


    // --------- INICIO DE LOGICA DE CAROUSEL --------- //

    const [show, setShow] = useState(false);
    const [idImg, setIdImg] = useState();

    const handleClose = () => { setShow(false); }
    const handleShow = (idPic) => {
        setShow(true)
        setIdImg(idPic)
        console.log('setIdImg()')

    }

    // --------- FIN DE LOGICA DE CAROUSEL --------- //


    return (

        <div>
            <div className='container'>

                <Modal show={show} size='lg' onHide={handleClose} ackdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>Equipos con los que trabajamos en Harispe Agrimensura.</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <CarouselEquipamiento index={ idImg }/>
                    </Modal.Body>

                    <Modal.Footer className={'modal__desc'} >
                        <small>Imágenes nuestro Equipamiento.</small>
                    </Modal.Footer>
                </Modal>
                <h3 className='bloque__titulo bloque__titulo--center'>NUESTROS EQUIPOS</h3>
                <div className='row pb-1 pt-5'>
                    
                    <div className="gallery__cont p-2 pb-4">
                        <div className="column">
                            <img onClick={ () => handleShow(0) } src="images/equipamiento/equipamiento-00.jpg" className="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="0" />
                            <img onClick={ () => handleShow(2) } src="images/equipamiento/equipamiento-03.jpg" className="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="2" />
                            <img onClick={ () => handleShow(3) } src="images/equipamiento/equipamiento-02.jpg" className="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="3" />
                        </div>
                        <div className="column">
                            <img onClick={ () => handleShow(1) } src="images/equipamiento/equipamiento-01.jpg" className="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="1" />
                            <img onClick={ () => handleShow(4) } src="images/equipamiento/equipamiento-04.jpg" className="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="4" />
                            <img onClick={ () => handleShow(7) } src="images/equipamiento/equipamiento-09.jpg" className ="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="7" />
                        </div>
                        <div className="column">
                            <img onClick={ () => handleShow(5) } src="images/equipamiento/equipamiento-07.jpg" className ="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="5" />
                            <img onClick={ () => handleShow(6) } src="images/equipamiento/equipamiento-08.jpg" className ="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="6" />
                            <img onClick={ () => handleShow(8) } src="images/equipamiento/equipamiento-06.jpg" className="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="8" />
                        </div>
                        
                        <div className="column">
                            <img onClick={ () => handleShow(9) } src="images/equipamiento/equipamiento-10.jpg" className ="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="9" />
                            <img onClick={ () => handleShow(10) } src="images/equipamiento/equipamiento-11.jpg" className ="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="10" />
                            <img onClick={ () => handleShow(11) } src="images/equipamiento/equipamiento-12.jpg" className ="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="11" />
                            <img onClick={ () => handleShow(12) } src="images/equipamiento/equipamiento-13.jpg" className ="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="12" />
                            <img onClick={ () => handleShow(13) } src="images/equipamiento/equipamiento-14.jpg" className ="d-block" alt="Equipos con los que trabajamos en Harispe Agrimensura" data-img-mostrar="13" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default GalleryEquipamiento