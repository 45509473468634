import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'
import CarouselQuienesSomosGallery from './CarouselQuienesSomosGallery';

import './Gallery.css'

const GalleryQuienesSomos = () => { 


    // --------- INICIO DE LOGICA DE CAROUSEL --------- //

    const [show, setShow] = useState(false);
    const [idImg, setIdImg] = useState();

    const handleClose = () => { setShow(false); }
    const handleShow = (idPic) => {
        setShow(true)
        setIdImg(idPic)
        console.log('setIdImg()')

    }

    // --------- FIN DE LOGICA DE CAROUSEL --------- //


    return (

        <div>
            <div className='container'>

                <Modal show={show} size='lg' onHide={handleClose} ackdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>Harispe Agrimensura.</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <CarouselQuienesSomosGallery index={ idImg }/>
                    </Modal.Body>

                    <Modal.Footer className={'modal__desc'} >
                        <small>Imágenes de lo que somos.</small>
                    </Modal.Footer>
                </Modal>

                <div className='row pb-1 pt-5'>
                    
                    <hr className='mb-5'/>                    
                    <div className="gallery__cont p-2 pb-4">
                        <div className="column">
                            <img onClick={ () => handleShow(0) } src="images/quienes-somos/quienes-somos-00.jpg" className="d-block" alt="Harispe Agrimensura" data-img-mostrar="0" />
                            <img onClick={ () => handleShow(2) } src="images/quienes-somos/quienes-somos-03.jpg" className="d-block" alt="Harispe Agrimensura" data-img-mostrar="3" />
                            <img onClick={ () => handleShow(1) } src="images/quienes-somos/quienes-somos-01.jpg" className="d-block" alt="Harispe Agrimensura" data-img-mostrar="1" />
                        </div>
                        <div className="column">
                            <img onClick={ () => handleShow(3) } src="images/quienes-somos/quienes-somos-02.jpg" className="d-block" alt="Harispe Agrimensura" data-img-mostrar="2" />
                            <img onClick={ () => handleShow(4) } src="images/quienes-somos/quienes-somos-04.jpg" className="d-block" alt="Harispe Agrimensura" data-img-mostrar="4" />
                        </div>
                        <div className="column">
                            <img onClick={ () => handleShow(5) } src="images/quienes-somos/quienes-somos-07.jpg" className ="d-block" alt="Harispe Agrimensura" data-img-mostrar="7" />
                            <img onClick={ () => handleShow(6) } src="images/quienes-somos/quienes-somos-08.jpg" className ="d-block" alt="Harispe Agrimensura" data-img-mostrar="8" />
                            <img onClick={ () => handleShow(7) } src="images/quienes-somos/quienes-somos-09.jpg" className ="d-block" alt="Harispe Agrimensura" data-img-mostrar="9" />
                            <img onClick={ () => handleShow(8) } src="images/quienes-somos/quienes-somos-06.jpg" className="d-block" alt="Harispe Agrimensura" data-img-mostrar="6" />
                        </div>
                        
                        <div className="column">
                            <img onClick={ () => handleShow(9) } src="images/quienes-somos/quienes-somos-10.jpg" className ="d-block" alt="Harispe Agrimensura" data-img-mostrar="10" />
                            <img onClick={ () => handleShow(10) } src="images/quienes-somos/quienes-somos-11.jpg" className ="d-block" alt="Harispe Agrimensura" data-img-mostrar="11" />
                            <img onClick={ () => handleShow(11) } src="images/quienes-somos/quienes-somos-12.jpg" className ="d-block" alt="Harispe Agrimensura" data-img-mostrar="12" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default GalleryQuienesSomos