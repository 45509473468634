import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import GalleryEquipamiento from '../common/GalleryEquipamiento'
import Header from '../common/Header'
import BannerEquipamiento  from '../common/BannerEquipamiento'

const Equipamiento = () => {
  return (
    <Container fluid className='p-0' >
      <Header urlImg='./images/headers/equipamiento.jpg' titulo='Equipamiento'/>
      <Container className='section__container'>
        <Row className='section__container--row'>
          <Col lg={12} className='section__container--col'>
              <p className='qsomos__text--bloque mb-0'>
              Contamos con equipamientos de última generación para brindar las mejores soluciones para nuestros clientes.<br /><br />
              </p>
          </Col>
        </Row >          
        
        <Row>
          
          <Col xs={12} lg={12} className='p-1'>
            <h3 className='bloque__titulo'>ESTACIONES TOTALES</h3>
          </Col>
          
          <Col xs={12} lg={6} className='pt-4'>
            <p>
              <strong>Marca:</strong> Topcon<br/>
              <strong>Modelo:</strong> GT603<br/>
            </p>
            <img className='w-100' src="./images/equipamiento/estacion-total-topcon-gt-603-header.jpg" alt="Estacion total GT-605" />
            <p className='pt-4'>
              Estacion TotaI Robotica con reconocimiento y seguimiento automatico deI prisma, marca Topcon, modeIo GT-605 de 5″de precision anguIar.<br/><br/>
              Con nuevas ópticas, seguimiento láser y procesamiento mejorado, la serie GT de Topcon cuenta gran velocidad y la productividad; ahorrándole tiempo y esfuerzo en cada trabajo.
            </p>
          </Col>

          <Col xs={12} lg={6} className='pt-4' >
            <p>
              <strong>Marca:</strong> Kolida<br/>
              <strong>Modelo:</strong> KTS 442L
            </p>
            <img className='w-100' src="./images/equipamiento/estacion-total-kolda-kts-442l.jpg" alt="Estacion total GT-605" />
            <p className='pt-4'>
              Instrumento compacto de última tecnología láser, alta calidad óptica, y una mecánica robusta y confiable. Equipada con sistema de compensación de doble eje.<br/><br/>
              Medición angular mediante codificador absoluto, sin necesidad de inicialización. Detectores angulares duales tanto en el círculo horizontal como en el vertical, para una mayor fiabilidad de lectura. Mandos horizontales y verticales agrupados en un mismo lateral, para mayor facilidad de uso
            </p>
          </Col>

        </Row>

      </Container>
      <Container fluid className='p-0' >
        <BannerEquipamiento />
      </Container>

      <Container className='pt-0 pb-3'>
        
          <Row xs={12} lg={12} >

            <Col xs={12} lg={4} className='pt-4'>
              <h3 className='bloque__titulo'>RECEPTORES GNSS</h3>
              <hr/>
              <Row>
              <Col xs={12} lg={12} className='pt-4'>
                  <img className='w-100' src="./images/equipamiento/receptores-gnss- topcon-hiper-vr.jpg" alt="Nivel optico wild NK10.jpg" />
                  <p className='pt-4' style={{ paddingRight: "2rem"}} > 
                    <strong>Marca:</strong> Topcon<br/>
                    <strong>Modelo:</strong> Hiper VR<br/><br/>
                    Se utiliza para posicionamiento preciso RTK y postproceso, con capacidad de rastreo muIticonsteIacion y Multifrecuencia, Ultra lIviano en peso integra radio modem UHF de gran aIcance y sensores inerciaIes y brújuLa para opcionaImente, usar compensación TiIt.<br/><br/>
                  </p>
                </Col>
              </Row>
            </Col>

            <Col xs={12} lg={8} className='pt-4'>
              <h3 className='bloque__titulo'>NIVELES ÓPTICOS</h3>
              <hr/>
              <Row>
              <Col xs={12} lg={6} className='pt-4'>
                  <img className='w-100' src="./images/equipamiento/nivel-optico-wild-nk10.jpg" alt="Nivel Optico wild nk10" />
                  <p className='pt-4' style={{ paddingRight: "2rem"}}> 
                    <strong>Marca:</strong> Wild<br/>
                    <strong>Modelo:</strong> NK10<br/><br/>
                    
                  </p>
                </Col>

                <Col xs={12} lg={6} className='pt-4'>
                  <img className='w-100' src="./images/equipamiento/nivel-optico-topcon-at-b2.jpg" alt="Nivel Optico Topcon at B2" />
                  <p className='pt-4'> 
                    <strong>Marca:</strong> Topcon<br/>
                    <strong>Modelo:</strong> AT-B2<br/><br/>
                    Nivel óptico automático de 32 aumentos, con un objetivo de 42mm y un error medio de 0,7 milimetros<br/><br/>
                  </p>
                </Col>
              </Row>
            </Col>
            
        </Row>
    </Container>

    <Container className='pb-5'>
        
        <Row>
          <Col xs={12} lg={6} className='pt-4'>
            <img className='w-100' src="./images/equipamiento/radio_motorola_talkabout_t200.jpg" alt="Radio Motorola Talkabout T200" />
            <h3 className='bloque__titulo'>RADIO</h3>
            <hr/>
            <p>
              <strong>Marca:</strong>  Motorola<br/>
              <strong>Modelo:</strong> Talkabout T200
            </p>
            <p className='pt-4'>
              Con un alcance de hasta 32 km y la libertad de comunicarse de forma inalámbrica, 
              el Talkabout T200 permite mantenerse en contacto, durante las tareas de campo, 
              aun cuando se pierda el contacto visual entre los equipos. Con 22 canales y 121  códigos de privacidad
            </p>
          </Col>

          <Col xs={12} lg={6} className='pt-4'>
            <img className='w-100' src="./images/equipamiento/distanciometro-bosch-glm-120c.jpg" alt="Distanciometro Bosch glm 120c" />
            <h3 className='bloque__titulo'>DISTANCIÓMETRO</h3>
            <hr/>
            <p>
              <strong>Marca:</strong>  Bosch<br/>
              <strong>Modelo:</strong> GLM 120
            </p>
            <p className='pt-4'>
            Es el medidor de distancias más completo de la categoría, con un alcance de hasta 120 metros, 
            que permite ver el punto del láser en la pantalla, gracias a una cámara integrada con zoom de 8x y 
            posee conectividad Bluetooth para dejar todas las medidas registradas en un smartphone o tablet


            </p>
          </Col>
        </Row>

      </Container>
      
      <Container fluid className='mt-5 pt-5 contact__container--grey'>
        <Row className='pt-2 pb-5'>
          <GalleryEquipamiento />
        </Row>
      </Container>
    </Container>
  )
}

export default Equipamiento