import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'
import CarouselLasLomadassGallery from './CarouselLasLomadasGallery';

import './Gallery.css'

const GalleryLasLomadasDeEscobar = () => { 


    // --------- INICIO DE LOGICA DE CAROUSEL --------- //

    const [show, setShow] = useState(false);
    const [idImg, setIdImg] = useState();

    const handleClose = () => { setShow(false); }
    const handleShow = (idPic) => {
        setShow(true)
        setIdImg(idPic)
        console.log('setIdImg()')

    }

    // --------- FIN DE LOGICA DE CAROUSEL --------- //


    return (

        <div>
            <div className='container'>

                <Modal show={show} size='lg' onHide={handleClose} ackdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>Proyecto Las Lomadas de Escobar.</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <CarouselLasLomadassGallery index={ idImg }/>
                    </Modal.Body>

                    <Modal.Footer className={'modal__desc'} >
                        <small>Imágenes del proyecto Las Lomadas de Escobar.</small>
                    </Modal.Footer>
                </Modal>

                <div className='row pb-1 pt-5'>
                    
                    <hr className='mb-5'/>                    
                    <div className="gallery__cont p-2 pb-4">

                        <div className="column">
                            <img onClick={ () => handleShow(0) } src="images/proyectos/las-lomadas-de-escobar.jpg" className="d-block" alt="Proyecto Las Lomadas de Escobar," data-img-mostrar="0" />
                        </div>

                        <div className="column">
                            <img onClick={ () => handleShow(1) } src="images/proyectos/las-lomadas-de-escobar-1.jpg" className="d-block" alt="Proyecto Las Lomadas de Escobar," data-img-mostrar="1" />
                        </div>

                        <div className="column">
                            <img onClick={ () => handleShow(2) } src="images/proyectos/las-lomadas-de-escobar-2.jpg" className="d-block" alt="Proyecto Las Lomadas de Escobar," data-img-mostrar="2" />
                        </div>

                        <div className="column">
                            <img onClick={ () => handleShow(3) } src="images/proyectos/las-lomadas-de-escobar-3.jpg" className="d-block" alt="Proyecto Las Lomadas de Escobar," data-img-mostrar="3" />
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    )
}

export default GalleryLasLomadasDeEscobar