import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Header from '../common/Header'

const Servicios = () => {
  return (
    
    <Container fluid className='p-0' >
      
      <Header urlImg='./images/headers/servicios.jpg' titulo='Servicios'/>
      
      <Container className='section__container'>
        <Row className='section__container--row mb-3'>
          <Col className='section__container--col'>
            <p className='qsomos__text--bloque'>
              Con más de 35 años de experiencia, contamos el conocimiento y equipamiento para llevar a cabo servicios profesionales acordes a las necesidades de nuestros clientes actuales y futuros.
              </p>
          </Col>
        </Row>
        <Row className='pt-4'>
          <Col xs={12} lg={4} className='bloque__img bloque__img--start'>
            <br/>
            <img src="./images/servicios/estados-parcelarios.jpg" alt="Estados Parcelarios." />
          </Col>
          
          <Col xs={12} lg={8}  className='qsomos__img'>
            <h3 className='bloque__titulo'>Estados parcelarios (cédula catastral Ley 10.707)</h3>
            <p className='bloque__text'>
              Es la tarea que realiza el profesional de la agrimensura con la finalidad de publicitar el estado de hecho de la cosa inmueble, en relación con el derecho de propiedad emergente de los títulos o de la posesión ejercida..<br/><br/>
              Se debe realizar ante toda transmisión, modificación o constitución de derechos reales sobre un inmueble, ya sea venta, donación, sucesión, hipoteca, etc. Lo que garantiza seguridad jurídica en la transmisión de dominio.<br/><br/>  
            </p>
            <h3 className='bloque__titulo'>Revalúos (DDJJ)</h3>
            <p className='bloque__text'>
              Es la tarea mediante la cual, el Agrimensor declara en ARBA, a pedido del comitente, sobre una modificación de las accesiones de la propiedad, es decir cuando se necesita declarar una nueva construcción o modificación de la misma, o bien, dar de baja por demolición. Este trámite actualiza la valuación fiscal de la propiedad.
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid className='section__container--banner'> 
        <Container >
          <Row>
            <Col xs={12} lg={12} className='section__title' style={{ textAlign: 'left'}}>
                <h2> MENSURA </h2>
            </Col>
            <Col xs={12} lg={6}  className='qsomos__img bloque__izq' >
              
              <h3 className='bloque__titulo'>Plano de subdivisión (loteos)</h3>
              <p className='bloque__text'>
                Se realizan cuando el propietario del inmueble quiere dividir su terreno, en dos o más parcelas de menor tamaño que la original. Para poder generar estas nuevas parcelas, hay que regirse en los códigos urbanos sancionados por los municipios, que establecen las dimensiones mínimas permitidas, y también por la Ley provincial N° 8.912, o en todo caso, adecuarse a las leyes especiales bajo la cual se quiere realizar la subdivisión (ejemplo Ley de Hábitat 14.449).
              </p>
              
              <h3 className='bloque__titulo'>Plano de unificación</h3>
              <p className='bloque__text'>
              Es el concepto opuesto a la subdivisión. En el cual, a partir de dos o más parcelas, se genera una de mayor tamaño. Diversos municipios suelen solicitar la unificación de parcelas, en los casos que una construcción se encuentre encaballada sobre el límite de ambos terrenos.
              </p>
            </Col>
            <Col xs={12} lg={6}  className='qsomos__img '>
              <h3 className='bloque__titulo'> Plano de subdivisión y anexión</h3>
              <p className='bloque__text'>
                Se realiza en el caso que un propietario, desea venderle a su lindero, una porción de terreno que no constituye una parcela independiente, es decir que no cuente con las dimensiones mínimas exigidas por la legislación actual. Suele darse también, en los casos que exista invasión entre linderos, y se quiera subsanar la situación.
              </p>
              <h3 className='bloque__titulo'>Plano de usucapión</h3>
              <p className='bloque__text'>
                Es el plano de mensura realizado por el Agrimensor, en el cual se delimita la voluntad del poseedor, y se ve reflejada en este documento.<br/><br/>Es uno de los requerimientos que necesita el abogado actuante, para llevar a cabo el juicio de usucapión (o posesión veinteañal)
              </p>
            </Col>
          </Row>
          
          <Row className='pt-4'> 
          <Col xs={12} lg={4} className='bloque__img'>
            <img src="./images/servicios/mensura.jpg" alt="Subdivision PH  ." />
          </Col>
          <Col xs={12} lg={8}  className='qsomos__img'>
            <h2 className='bloque__titulo'> 
              MENSURA PARA SOMETER A REGIMEN DE PROPIEDAD HORIZONTAL (PH)<br />
              Plano de subdivisión por Propiedad Horizontal (PH)
              </h2>
            <p className='bloque__text'>
              Los planos sometidos al régimen de PH (anteriormente Ley 13512, hoy es un Derecho Real incorporado al Código Civil y Comercial), vienen a cambiar el concepto de verticalidad de la propiedad privada, generando propiedades por niveles. En las cuales se crean subparcelas (a través de polígonos), donde se da propiedad exclusiva a las mismas (o unidades funcionales) y condominio para la parcela base y los espacios comunes.<br/><br/>
              Si bien no hay dimensiones mínimas para crear estas subparcelas, las mismas deben contar al menos con baño y cocina. Y para poder realizar el plano de Propiedad Horizontal, es requisito necesario, contar con el plano de obra aprobado por la Municipalidad competente.
            </p>
          </Col>
        </Row>
        </Container>
      </Container>

      <Container className='section__container'>
        <Row>
          <Col xs={12} lg={8}  className='qsomos__img bloque__izq' >
            <h3 className='bloque__titulo'>Amojonamientos</h3>
            <p className='bloque__text'>
              Es la tarea profesional mediante la cual, se materializan los límites jurídicos del bien, en el terreno. Es decir, mediante la interpretación del título de propiedad, se colocan los mojones en los vértices del inmueble en su correcta ubicación respecto de la manzana.<br/><br/>
              Muchas municipalidades de la provincia, solicitan a los propietarios antes de que edifiquen, que realicen el Certificado de Deslinde y Amojonamiento, documento que debe contar con visado del Consejo Profesional de Agrimensura, y ocasionalmente, la aprobación de la municipalidad competente.
            </p>
          </Col>
          <Col xs={12} lg={4} className='bloque__img'>
            <img src="./images/servicios/amojonamientos.jpg" alt="Amojonamientos." />
          </Col>
          </Row>
      </Container>



      <Container fluid className='section__container--banner'> 
        <Container >
          <Row className='pt-4'> 
            <Col xs={12} lg={4} className='bloque__img  bloque__img--start' >
              <img src="./images/servicios/vinculaciones-satelitales.jpg" alt="Vinculaciones Satelitales." />  
              <h3 className='bloque__titulo'>Vinculaciones satelitales.</h3>
              <p className='bloque__text'>
              En todos los casos de mensuras rurales, o de inmuebles cuya nomenclatura catastral no tenga categoría de Manzana, se requiere la georreferenciación del bien, a un punto conocido de la Red GEOBA. Lo que consta de realizar mediante receptores GNSS (GPS), posicionamientos estáticos de tiempos variables según la distancia a la estación permanente operativa más cercana, que luego de un postproceso de los datos crudos, se llega a conocer las coordenadas precisas de los puntos, con una precisión del centímetro
              </p>
            </Col>
            <Col xs={12} lg={4} className='bloque__img  bloque__img--start'>
              <img src="./images/servicios/relevamientos.jpg" alt="Relevamientos Topograficos." />  
              <h3 className='bloque__titulo'>Relevamientos topográficos.</h3>
              <p className='bloque__text'>
              Pudiendo ser relevamientos planimétricos, altimétricos, o la combinación de ambos.<br/><br/>Se realizan con las técnicas más acordes para las necesidades del cliente, pudiendo ser por ejemplo, plano de potreros en inmuebles rurales, determinación de áreas, escurrimientos, relevamientos para proyectos de pavimento y cordón cuneta, curvas de nivel, perfiles transversales y longitudinales, etc.
              </p>
            </Col>
            <Col xs={12} lg={4} className='bloque__img  bloque__img--start'>
              <img src="./images/servicios/fotogrametria.jpg" alt="Fotogrametría." />  
              <h3 className='bloque__titulo'>Fotogrametría.</h3>
              <p className='bloque__text'>
              Con el avance de la tecnología y el adevenimiento de nuevos equipamientos, lo que parecía tan utópico como realizar fotogrametría con aviones y cámaras de gran porte, se vió facilitado gracias a la aparición de los VANT (drones), mediante los cuales realizando una planificación de vuelo y el correcto apoyo de campo, logramos productos de gran precisión como ser: Ortomosaicos, Modelos digitales de elevación, cálculo de volúmenes, Curvas de nivel y escurrimiento de aguas, y nube de puntos tridimensional con su malla texturizada de la cual se extrae gran cantidad de información como por ejemplo perfiles o simulaciones de inundación.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

    </Container>

  )
}

export default Servicios