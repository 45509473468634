import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselQuienesSomosGallery = ( props ) => {
  
    const [index, setIndex] = useState(props.index);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
        
        return (

        <section>
            <Carousel activeIndex={index} className='carousel__box  animated fast fadeIn' indicators={ false } onSelect={ handleSelect } >
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-00.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-01.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-03.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-02.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-04.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-07.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-08.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-09.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-06.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-10.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-11.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/quienes-somos/quienes-somos-12.jpg" alt="Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
            </Carousel> 
        </section>

    )
}

export default CarouselQuienesSomosGallery