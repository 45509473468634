import React from 'react'
import { Container } from 'react-bootstrap'

const HeaderMap = ( ) => {
  
  return (

    <Container fluid className='p-0 header__container animated fast fadeIn' style={{ backgroundColor: "rgba(0,0,0,0.1)"}}>
        
        <div className='contact__map'>
          <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3232.937513508932!2d-57.90156169814758!3d-35.87504812583617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95984f46a4b0daf7%3A0xe9af032dc4cf6247!2sIndependencia%2C%20Lezama%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1659306309417!5m2!1ses-419!2sar" 
          width="100%" 
          height="450"
          style={{ border: '0', marginTop: '100px'}}
          loading="lazy" 
          title='Harispe Agrimensura'/>
      </div>
        
    </Container>

  )
}

export default HeaderMap