import React from 'react'
import { Card } from 'react-bootstrap'
import ButtonComponent from './ButtonComponent'
import { Link } from 'react-router-dom'

const CardSite = ( { img, alt, title, text, link }  ) => {

  // const props = {
  //   img: "ulr",
  //   alt: "descripcion de la imagen",
  //   title: "nombre del servicio",
  //   text: "descprion del servicio",
  //   link: "url link "
  // }

  return (
    <Card className='card__container'>
        <Card.Img variant="top" src={ img } alt={ alt } />
        <Card.Body className='cards__body' >
          <Card.Title className='cards__title'>{ title }</Card.Title>
          <Card.Text className='cards__text'>
            { text }
          </Card.Text>
          <Link  to={link}> <ButtonComponent  text={'Mas Info'} classButton={'button-rounded'}  /></Link>
        </Card.Body>
    </Card>
  )
}

export default CardSite

