import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import GalleryLasLomadasDeEscobar from '../common/GalleryLasLomadasDeEscobar'
import Header from '../common/Header'

const Proyectos = () => {
  return (

    <Container fluid className='p-0' >
      <Header urlImg='./images/headers/proyectos.jpg' titulo='Proyectos'/>
        <Container className='section__container'>
          <Row className='section__container--row'>
            <Col xs={12} lg={6} className='section__container--col'>
            <h3 className='bloque__titulo'>Las Lomadas de Escobar </h3>
              <p className='qsomos__text--bloque'>
                <strong>Dimensión:</strong> 8 Hectareas | 170 Terrenos<br />
                <strong>Tareas:</strong> Relevamiento topografico, Modelo tridimensional con drone y Plano mensura<br />
                <strong>Ubicación:</strong> Manteu, Escobar, Pcia. de Buenos Aires, Argentina<br />
             </p>
             <p >
                <a href='https://www.laslomadasdeescobar.com.ar' target={'_blank'}  rel='noreferrer' className='aLink'>
                  www.laslomadasdeescobar.com.ar
                </a>
              </p>
            </Col>
            <Col xs={12} lg={6} className='qsomos__img'>
              <img src="./images/proyectos/las-lomadas-de-escobar.jpg" alt="Las Lomadas de Escobar" />
            </Col>
          
          </Row>
          <Row className='pt-4'>
            <GalleryLasLomadasDeEscobar />
          </Row>
        </Container>
    </Container>
    
  )
}

export default Proyectos