import { React, useState } from 'react'
import { Navbar, Container, Nav} from 'react-bootstrap' 
import { Link } from 'react-router-dom'
import Logo from '../../assets/navbar-logo.svg'

import { useScrollPosition } from '../../hooks/useScrollPosition.js'

const NavbarSite = () => {

  const scrollPosition = useScrollPosition();  
  // const [ isOpen , setIsOpen] = useState(false)

  // const toggleIsOpen = event => {
  //   // console.log("toggleIsOpen : ", current)
  //   // setIsOpen(current => !current)
  // }

  

  const closeHamburgerMenu = () => {
    const itemMenu = document.getElementById('menu-toggle');
    itemMenu.classList.add("collapsed")
    
    const itemShow = document.getElementById('basic-navbar-nav');
    itemShow.classList.remove("show")
  }

  return (
    
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top" className={scrollPosition > 0 ? 'navbar__scrolling': ''} >
      <Container>
        <Link to={'/'}  style={{ cursor: 'pointer'}}>
             <Navbar.Brand className={scrollPosition > 0 ? 'navbar-brand__scrolling': ''}> 
                <img src={ Logo } alt="Harispe Agrimensura" />
              </Navbar.Brand>
          </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id="menu-toggle"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to={'quienes-somos'} className={'nav-link'} style={{ cursor: 'pointer'}} aria-controls="basic-navbar-nav" onClick={closeHamburgerMenu}>Quienes somos</Link>
            <Link to={'servicios'} className={'nav-link'} style={{ cursor: 'pointer'}} onClick={closeHamburgerMenu}>Servicios</Link>
            <Link to={'proyectos'} className={'nav-link'} style={{ cursor: 'pointer'}} onClick={closeHamburgerMenu}>Proyectos</Link>
            <Link to={'equipamiento'} className={'nav-link'} style={{ cursor: 'pointer'}} onClick={closeHamburgerMenu}>Equipamiento</Link>
            <Link to={'preguntas-frecuentes'} className={'nav-link'} style={{ cursor: 'pointer'}} onClick={closeHamburgerMenu}>Preguntas Frecuentes</Link>
            <Link to={'contacto'} className={'nav-link'} style={{ cursor: 'pointer'}}>Contacto</Link>
            <span  className="nav-link--pipe preg__desktop" ><i className="fa-solid fa-pipe"></i> | </span>
            <a href="https://www.instagram.com/harispe.agrimensura/" target={'_blank'} rel="noreferrer"  style={{ cursor: 'pointer'}}>
              <p className='nav-link--wapp'> <i className="fa-brands fa-instagram fa-xl"></i> </p>
            </a>
            <a href="https://www.facebook.com/harispe.agrimensura/" target={'_blank'} rel="noreferrer"  style={{ cursor: 'pointer'}}>
              <p className='nav-link--wapp'> <i className="fa-brands fa-facebook fa-xl"></i> </p>
            </a>
            <a href="https://api.whatsapp.com/send?phone=5492241558440" target={'_blank'} rel="noreferrer"  style={{ cursor: 'pointer'}}>
              <p className='nav-link--wapp'> <i className="fa-brands fa-whatsapp fa-xl"></i> </p>
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  
  )
}

export default NavbarSite