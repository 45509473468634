import React from 'react'
import { Container, Row } from 'react-bootstrap'
import HeaderMap from '../common/HeaderMap'

const Contacto = () => {
  return (
    <div className='contact__container'>
      <HeaderMap urlImg='./images/headers/clientes.jpg' titulo='Proovedores'/>
     
     <Container  className='p-5 pb-0'>
         <Row className='justify-content-lg-center'>
          <p className='qsomos__text--bloque contact__text'>Estamos a disposición por cualquier duda o consulta que quieran hacernos.</p>
         </Row>
       </Container>
    </div>
  )
}

export default Contacto