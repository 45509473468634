import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselLasLomadasGallery = ( props ) => {
  
    const [index, setIndex] = useState(props.index);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
        
        return (

        <section>
            <Carousel activeIndex={index} className='carousel__box  animated fast fadeIn' indicators={ false } onSelect={ handleSelect } >
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proyectos/las-lomadas-de-escobar.jpg" alt="Proyecto Las Lomadas de Escoba" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proyectos/las-lomadas-de-escobar-1.jpg" alt="Proyecto Las Lomadas de Escoba" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proyectos/las-lomadas-de-escobar-2.jpg" alt="Proyecto Las Lomadas de Escoba" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/proyectos/las-lomadas-de-escobar-3.jpg" alt="Proyecto Las Lomadas de Escoba" loading="lazy" />  </Carousel.Item>
            </Carousel> 
        </section>

    )
}

export default CarouselLasLomadasGallery