import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ButtonComponent from './ButtonComponent'
import BannerDron from '../../assets/banner-dron-icon.svg'
import { Link } from 'react-router-dom'

const Banner = ({link }) => {

  return (

    <Container fluid className='banner__container' >
      <Row className='justify-content-lg-center banner__container--gutter'>
        <Col md lg="3" className='banner__container--drone'>
          <img src={BannerDron} alt="Icono de Dron." />
        </Col>
        <Col md lg="3"className='banner__container--col'>
          <div className='banner__title'>Tecnología</div>
          <div className='banner__text'>Contamos con equipamiento de última generación que nsos permiten brindar informes actualizados y modernos</div>
          <div className='banner__container--button'>
            <Link  to={link}> <ButtonComponent  text={'Mas Info'} classButton={'button-rounded--outline'}  /></Link>
          </div>
        </Col>
          <Col xs lg="2">
        </Col>
      </Row>
    </Container>
    
  )
}

export default Banner