import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BannerDron from '../../assets/banner-dron-icon.svg'

const BannerEquipamiento = () => {

  return (

    <Container fluid className='banner__container' >
      <Row className='justify-content-lg-center banner__container--gutter'>
        <Col md lg="3" className='banner__container--drone'>
          {/* <img src={BannerDron} alt="Icono de Dron." /> */}
          <img src='../images/equipamiento/drone-icono-banner.jpg'className='w-100' alt="Icono de Dron." />

        </Col>
        <Col md lg="5"className='banner__container--col'>
          <div className='banner__title'>Drone</div>
          <div className='banner__text'>
            <strong>Marca:</strong> Parrot |  <strong>Modelo:</strong> Anafi<br/>
            Es ultra compacto, ligero y dispone de una cámara 4K/21 MP con orientación vertical en 180°, 
            la que facilita la captura de imágenes en áreas inaccesibles. Esta herramienta de trabajo está diseñada para un rápido vuelo con una duración de hasta 1 hora con 40 minutos, al emplear baterías inteligentes
            </div> 
        </Col>
          <Col xs lg="3">
        </Col>
      </Row>
    </Container>
    
  )
}

export default BannerEquipamiento