import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

const CarouselEquipamiento = ( props ) => {
  
    const [index, setIndex] = useState(props.index);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
        
        return (

        <section>
            <Carousel activeIndex={index} className='carousel__box  animated fast fadeIn' indicators={ false } onSelect={ handleSelect } >
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-00.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-01.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-03.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-02.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-04.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-07.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-08.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-09.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-06.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-10.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-11.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-12.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-13.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
                <Carousel.Item> <img  className="d-block  carousel__box--image" src="./images/equipamiento/equipamiento-14.jpg" alt="Equipos con los que trabajamos en Harispe Agrimensura" loading="lazy" />  </Carousel.Item>
            </Carousel> 
        </section>

    )
}

export default CarouselEquipamiento