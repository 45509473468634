import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Banner from './common/Banner'
import CardSite from './common/CardSite'
import CarouselSite from './common/CarouselSite'
import PreguntasFrecuentesComp from './common/PreguntasFrecuentesComp'

const Home = () => {
  return (
    
    <Container fluid className='p-0 mb-0' style={{ backgroundColor: "rgba(0,0,0,0.1)"}} >

        <CarouselSite />

        <Container fluid  className='bg-texture'>
          <Container className='cards__container'>
              <h2 className='section__title'>Servicios que realizamos</h2>
              <Row className='cards__container--row'>
                  <Col xs={12} md={4}>
                    <CardSite 
                      img='./images/home-propiedad-horizontal.jpg' 
                      alt='Foto trabajando en relevamiento de propiedad horizontal'
                      title='Propiedad horizontal' 
                      text='Planos sometidos al régimen de PH, cambian el concepto de verticalidad de la propiedad privada, generando propiedades por niveles.'
                      link='servicios'/>
                  </Col>
                  <Col xs={12} md={4}>
                  <CardSite 
                      img='./images/home-mensura.jpg' 
                      alt='Foto de equipo para realizar mensuras'
                      title='Mensura' 
                      text="Se realizan cuando el propietario del inmueble quiere dividir su terreno, en dos o más parcelas de menor tamaño que la original."
                      link='servicios'/>
                  </Col>
                  <Col xs={12} md={4}>
                  <CardSite 
                      img='./images/home-estado-parcelario.jpg' 
                      alt='Foto trabajando en un estado parcelario '
                      title='Estado parcelario' 
                      text='Constituimos o verificamos el estado parcelario según Ley 10.707 para garantizar la seguridad jurídica en la transmisión del inmueble.'
                      link='servicios'/>
                  </Col>
              </Row>
          </Container>
        </Container> 
          <Banner link='servicios'/>
        <Container>
          <PreguntasFrecuentesComp/>
        </Container>
    </Container>

  )
}

export default Home